// libs
import React, { useEffect } from 'react';
import get from 'lodash/get';

// analytics
import { onPageLoad } from 'shared/layouts/Homepage/analytics';

// components
import GenericBrandDesc from 'shared/components/Footer/components/GenericBrandDesc';
import WidgetRendererDesktop from 'shared/components/WidgetRendererSwitch/Desktop';
import MetaInfo from 'shared/components/AdPlatformWidgets/components/MetaInfo';
import IOElement from 'shared/components/IOElement';

// styles
import { ScrollToTop } from './styles';

// hook
import useCsrSsrSplit from 'shared/hooks/useCsrSsrSplit';
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

// defs
import { ILandingPageProps } from 'shared/components/AdPlatformWidgets/types';

//helpers
import { getMetaData } from 'shared/components/AdPlatformWidgets/helpers';

interface IHomepageChild {
  homepageData: ILandingPageProps['landingPageData'];
  onIntlNetwork: boolean;
  pageName: string;
}

function Child({ homepageData, onIntlNetwork, pageName }: IHomepageChild) {
  // hook
  const {
    pageFirstFoldData,
    pageBelowTheFoldData,
    showBelowTheFold,
    onVisible,
    eagerLoadingCount,
  } = useCsrSsrSplit(homepageData);
  const showFooterCategories = get(useRemoteConfig('footerSection'), 'showCategories', false);

  useEffect(() => {
    onPageLoad({ onIntlNetwork, NDNPageType: pageName });
  }, [onIntlNetwork, pageName]);

  const metaData = getMetaData(homepageData);

  return (
    <>
      <MetaInfo metaData={metaData} />
      <WidgetRendererDesktop pageName={pageName} landingPageData={pageFirstFoldData} />
      <IOElement onVisible={onVisible} once />
      {showBelowTheFold && (
        <WidgetRendererDesktop
          landingPageData={pageBelowTheFoldData}
          pageName={pageName}
          eagerLoadingCount={eagerLoadingCount}
        />
      )}
      {showFooterCategories && <GenericBrandDesc />}
      <ScrollToTop />
    </>
  );
}

export default Child;
