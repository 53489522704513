// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';

// constants
import { BASE_GUTTER_SPACE, DWEB_MAX_WIDTH } from 'shared/styles/base';

// styles
const Banner = styled.div`
  padding-top: 15%;
  margin-bottom: ${({ theme }) => theme.spacing.spacing200};
  background: ${({ theme }) => theme.colors.surface20};
`;

const Title = styled.p`
  height: 18px;
  background: ${({ theme }) => theme.colors.surface20};
  width: 20%;
  margin: ${({ theme }) => theme.spacing.spacing80};
`;

const Img = styled.div`
  padding-top: 15%;
  width: calc(50% - ${BASE_GUTTER_SPACE * 4}px);
  margin: ${({ theme }) => theme.spacing.spacing80};
  background: ${({ theme }) => theme.colors.surface20};
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Container = styled.div`
  min-height: 100vh;
`;

const Main = styled.div`
  max-width: ${DWEB_MAX_WIDTH}px;
  margin: 0 auto;
`;

function Placeholder() {
  return (
    <Container>
      <Banner />
      <Main>
        <Title />
        <ImageWrapper>
          <Img />
          <Img />
          <Img />
          <Img />
          <Img />
          <Img />
        </ImageWrapper>
      </Main>
    </Container>
  );
}

export default memo(Placeholder);
