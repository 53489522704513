// libs
import { styled } from '@nykaa/ui-components';

// components
import BaseScrollToTop from 'shared/components/ScrollToTop';

export const ScrollToTop = styled(BaseScrollToTop)`
  bottom: 95px;
  right: 30px;
`;
