//Libraries
import React, { memo } from 'react';

// component
import HomePageAPI from 'shared/layouts/Homepage/HomePageAPI';
import Child from './Child';
import Placeholder from './Placeholder';

// hooks
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';
import useDealsPlatformRedirect from 'shared/hooks/useDealsPlatformRedirect';

function HomepageDesktop() {
  const { dWebPageName } = useRemoteConfig('hp');
  const shouldRenderWithDealsPlatform = useDealsPlatformRedirect(dWebPageName);

  return (
    <HomePageAPI
      pageName={dWebPageName}
      placeholder={<Placeholder />}
      shouldTransformForDealsPlatform={shouldRenderWithDealsPlatform}
    >
      {Child}
    </HomePageAPI>
  );
}

export default memo(HomepageDesktop);
